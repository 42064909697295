import axios from 'axios'
import * as xml2json from 'xml-js'

const baseURL = process.env.VUE_APP_BACKEND_URL.replace(/\/+$/, '')

export default {
    methods: {
        /**
         * @description SFMC REST API Call for retrieving the context data of an OAuth token
         * @param {string} oauthToken Valid OAuth token from SFMC
         */
        getTokenContextREST (oauthToken, fuelApiRestHost) {
            const options = {
                headers: {
                    Authorization: `Bearer ${oauthToken}`,
                },
            }

            return axios.get(`${baseURL}/proxyPWA/${fuelApiRestHost}/platform/v1/tokenContext`, options)
                .then(response => response)
                .catch((err) => {
                    this.$log.error(`Request failed. ${err}`)
                    return err
                })
        },

        /**
         * @description SFMC REST API Call for retrieving the details of a Journey Builder Event (EventDefinition)
         * @param {string} oauthToken Valid OAuth token from SFMC
         * @param {string} eventDefinitionKey EventDefinitionKey
         */
        getEventDefinitionREST (oauthToken, fuelApiRestHost, eventDefinitionKey) {
            const options = {
                headers: {
                    Authorization: `Bearer ${oauthToken}`,
                },
            }
            return axios.get(`${baseURL}/proxyPWA/${fuelApiRestHost}/interaction/v1/eventDefinitions/Key:${eventDefinitionKey}`, options)
                .then(response => response)
                .catch((err) => {
                    this.$log.error(`Request failed. ${err}`)
                    return err
                })
        },

        /**
         * @description SFMC SOAP API Call for retrieving a list of Data Extensions using a complex filter
         * which checks if the received searchValue is like the Data Extension's Name or CustomerKey
         * @param {string} accessToken Valid OAuth token from SFMC
         * @param {string} searchValue Text to be used in the partial search (like)
         */
        dataExtensionSearchByNameOrCustomerKey (accessToken, searchValue) {
            this.$log.debug(`(SOAP CALL) Retrieve DataExtension by "Name" or "CustomerKey" like "${searchValue}"`)
            const filter = `
              <Filter xsi:type="ComplexFilterPart">
                <LeftOperand xsi:type="SimpleFilterPart">
                  <Property>Name</Property>
                  <SimpleOperator>like</SimpleOperator>
                  <Value>${searchValue}</Value>
                </LeftOperand>
                <LogicalOperator>OR</LogicalOperator>
                <RightOperand xsi:type="SimpleFilterPart">
                  <Property>CustomerKey</Property>
                  <SimpleOperator>like</SimpleOperator>
                  <Value>${searchValue}</Value>
                </RightOperand>
              </Filter>
              `
            return this.dataExtensionSearch(accessToken, filter)
        },

        /**
         * @description SFMC SOAP API Call for retrieving a list of Data Extensions using a simple filter built from the received parameters
         * @param {string} accessToken Valid OAuth token from SFMC
         * @param {string} attributeName Field Name to be used in the filter
         * @param {string} operator Search operator to be used in the filter
         * @param {string} attributeValue  Field Value to be used in the filter
         */
        dataExtensionSearchBySimpleAttribute (accessToken, attributeName, operator, attributeValue) {
            this.$log.debug(`(SOAP CALL) Retrieve DataExtension by "${attributeName}" ${operator} "${attributeValue}"`)
            const filter = `
              <Filter xsi:type="SimpleFilterPart">
                <Property>${attributeName}</Property>
                <SimpleOperator>${operator}</SimpleOperator>
                <Value>${attributeValue}</Value>
              </Filter>
              `
            return this.dataExtensionSearch(accessToken, filter)
        },

        /**
         * @description SFMC SOAP API Call for retrieving data from a Data Extension
         * @param {string} accessToken Valid OAuth token from SFMC
         * @param {string} deExternalKey External Key (CustomerKey) of the data extension
         * @param {string} deSearchField Field Name to be used in the filter
         * @param {string} deSearchFieldValue Field Value to be used in the filter
         * @param {string} deSearchOperator Search operator to be used in the filter
         * @param {array} deReturnFields Array of Field Names to be returned by the API call
         */
        lookupDESearch (accessToken, deExternalKey, deSearchField, deSearchFieldValue, deSearchOperator, deReturnFields) {
            let filter = null
            if (deSearchFieldValue) {
                filter = `
                  <Filter xsi:type="SimpleFilterPart">
                    <Property>${deSearchField}</Property>
                    <SimpleOperator>${deSearchOperator}</SimpleOperator>
                    <Value>${deSearchFieldValue}</Value>
                  </Filter>
                `
                    } else {
                        filter = `
                  <Filter xsi:type="SimpleFilterPart">
                    <Property>${deSearchField}</Property>
                    <SimpleOperator>notEquals</SimpleOperator>
                    <Value>-999999999</Value>
                  </Filter>
                `
            }

            let props = ''
            deReturnFields.forEach((prop) => {
                props += `<Properties>${prop}</Properties>`
            })

            const envelope = `
              <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
                <soapenv:Header>
                    <fueloauth xmlns="http://exacttarget.com">${accessToken}</fueloauth>
                </soapenv:Header>
                <soapenv:Body>
                    <RetrieveRequestMsg xmlns="http://exacttarget.com/wsdl/partnerAPI">
                      <RetrieveRequest>
                          <ObjectType>DataExtensionObject[${deExternalKey}]</ObjectType>
                          ${props}
                          ${filter}
                      </RetrieveRequest>
                    </RetrieveRequestMsg>
                </soapenv:Body>
              </soapenv:Envelope>
              `

            return axios.post(`${baseURL}/proxyPWA/https://${process.env.VUE_APP_SFMC_API_SUBDOMAIN}.soap.marketingcloudapis.com/Service.asmx`, envelope, {
                headers: {
                    'Content-Type': 'text/xml',
                    SOAPAction: 'Retrieve',
                },
            }).then((res) => {
                const json = JSON.parse(xml2json.xml2json(res.data, {
                    compact: true,
                }))
                const result = json['soap:Envelope']['soap:Body'].RetrieveResponseMsg
                return this.formatResponseSOAPDataExtensionObject(result)
            }).catch((err) => {
                this.$log.error('SOAP Exception', err)
                return err
            })
        },

        /**
         * @description SFMC SOAP API Call for retrieving Data Extensions. The filter node of the envelope must be provided.
         * @param {string} accessToken Valid OAuth token from SFMC
         * @param {string} filter String with the <filter> node of the SOAP envelope
         */
        dataExtensionSearch (accessToken, filter) {
            const envelope = `
      <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
        <soapenv:Header>
            <fueloauth xmlns="http://exacttarget.com">${accessToken}</fueloauth>
        </soapenv:Header>
        <soapenv:Body>
            <RetrieveRequestMsg xmlns="http://exacttarget.com/wsdl/partnerAPI">
              <RetrieveRequest>
                  <ObjectType>DataExtension</ObjectType>
                  <Properties>Name</Properties>
                  <Properties>CustomerKey</Properties>
                  <Properties>ObjectID</Properties>
                  <Properties>Description</Properties>
                  <Properties>Status</Properties>
                  <Properties>CategoryID</Properties>
                  <Properties>SendableSubscriberField.Name</Properties>
                  <Properties>SendableDataExtensionField.Name</Properties>
                  ${filter}
              </RetrieveRequest>
            </RetrieveRequestMsg>
        </soapenv:Body>
      </soapenv:Envelope>
      `

            return axios.post(`${baseURL}/proxyPWA/https://${process.env.VUE_APP_SFMC_API_SUBDOMAIN}.soap.marketingcloudapis.com/Service.asmx`, envelope, {
                headers: {
                    'Content-Type': 'text/xml',
                    SOAPAction: 'Retrieve',
                },
            }).then((res) => {
                const json = JSON.parse(xml2json.xml2json(res.data, {
                    compact: true,
                }))
                const result = json['soap:Envelope']['soap:Body'].RetrieveResponseMsg

                return this.formatResponseSOAPDataExtension(result)
            }).catch((err) => {
                this.$log.error('SOAP Exception', err)
                return err
            })
        },

        /**
         * @description SFMC SOAP API Call for retrieving Data Extension Fields given a DE's Customer Key
         * @param {string} accessToken Valid OAuth token from SFMC
         * @param {string} deCustomerKey External Key (CustomerKey) of the data extension
         */
        dataExtensionFieldsSearch (accessToken, deCustomerKey) {
            const filter = `
      <Filter xsi:type="SimpleFilterPart">
        <Property>DataExtension.CustomerKey</Property>
        <SimpleOperator>equals</SimpleOperator>
        <Value>${deCustomerKey}</Value>
      </Filter>
      `

            const envelope = `
      <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
        <soapenv:Header>
            <fueloauth xmlns="http://exacttarget.com">${accessToken}</fueloauth>
        </soapenv:Header>
        <soapenv:Body>
            <RetrieveRequestMsg xmlns="http://exacttarget.com/wsdl/partnerAPI">
              <RetrieveRequest>
                  <ObjectType>DataExtensionField</ObjectType>
                  <Properties>Name</Properties>
                  <Properties>FieldType</Properties>
                  <Properties>IsPrimaryKey</Properties>
                  <Properties>IsRequired</Properties>
                  <Properties>MaxLength</Properties>
                  <Properties>Scale</Properties>
                  <Properties>Ordinal</Properties>
                  ${filter}
              </RetrieveRequest>
            </RetrieveRequestMsg>
        </soapenv:Body>
      </soapenv:Envelope>
      `

            return axios.post(`${baseURL}/proxyPWA/https://${process.env.VUE_APP_SFMC_API_SUBDOMAIN}.soap.marketingcloudapis.com/Service.asmx`, envelope, {
                headers: {
                    'Content-Type': 'text/xml',
                    SOAPAction: 'Retrieve',
                },
            }).then((res) => {
                const json = JSON.parse(xml2json.xml2json(res.data, {
                    compact: true,
                }))
                const result = json['soap:Envelope']['soap:Body'].RetrieveResponseMsg
                return this.formatResponseSOAP(result)
            }).catch((err) => {
                this.$log.error('SOAP Exception', err)
                return err
            })
        },

        // ---------------------------------------------------------------------

        /**
         * @description Receives the raw SOAP response from a DataExtensionObject Retrieve call and produces a normalized Array of objects
         * @param {string} soapData SOAP response
         */
        formatResponseSOAPDataExtensionObject (soapData) {
            const results = []
            if (soapData.OverallStatus._text === 'OK') {
                if (soapData.Results) {
                    let soapResults = []
                    if (soapData.Results instanceof Array) {
                        soapResults = soapData.Results
                    } else {
                        soapResults.push(soapData.Results)
                    }

                    soapResults.forEach((aResult) => {
                        const aFormattedResult = {}
                        aResult.Properties.Property.forEach((aResultValue) => {
                            aFormattedResult[aResultValue.Name._text] = aResultValue.Value._text
                        })
                        results.push(aFormattedResult)
                    })
                }
            }

            return results
        },

        /**
         * @description Receives the raw SOAP response from a DataExtension Retrieve call and produces a normalized Array of objects
         * @param {string} soapData SOAP response
         */
        formatResponseSOAPDataExtension (soapData) {
            const results = []

            if (soapData.OverallStatus._text === 'OK') {
                if (soapData.Results) {
                    let soapResults = []
                    if (soapData.Results instanceof Array) {
                        soapResults = soapData.Results
                    } else {
                        soapResults.push(soapData.Results)
                    }

                    soapResults.forEach((aResult) => {
                        const aFormattedResult = {}
                        Object.keys(aResult).forEach((aResultField) => {
                            switch (aResultField) {
                                case 'SendableSubscriberField':
                                case 'SendableDataExtensionField':
                                    aFormattedResult[aResultField] = aResult[aResultField].Name._text
                                    break
                                default:
                                    aFormattedResult[aResultField] = aResult[aResultField]._text
                            }
                        })
                        results.push(aFormattedResult)
                    })
                    this.$log.debug(`(SOAP CALL) Response ${JSON.stringify(results)}`)
                }
            }

            return results
        },

        /**
         * @description Receives the raw SOAP response and produces a normalized Array of objects
         * @param {string} soapData SOAP response
         */
        formatResponseSOAP (soapData) {
            const results = []
            if (soapData.OverallStatus._text === 'OK') {
                if (soapData.Results) {
                    let soapResults = []
                    if (soapData.Results instanceof Array) {
                        soapResults = soapData.Results
                    } else {
                        soapResults.push(soapData.Results)
                    }

                    soapResults.forEach((aResult) => {
                        const aFormattedResult = {}
                        Object.keys(aResult).forEach((aResultField) => {
                            aFormattedResult[aResultField] = aResult[aResultField]._text
                        })
                        results.push(aFormattedResult)
                    })
                    this.$log.debug(`(SOAP CALL) Response ${JSON.stringify(results)}`)
                }
            }

            return results
        },
    },
}
